import Mailchimp from 'react-mailchimp-form';

function mail() {
    return (

        <Mailchimp
              action='https://gmail.us7.list-manage.com/subscribe/post?u=dc5c31be2600423c3ad7494f0&amp;id=caa7dc1f83'
                fields=
                {[
                  {
                    name: 'EMAIL',
                    placeholder: 'Email Address',
                    type: 'email',
                    required: true
                  }
                ]}
                submitButtonText="Register"
        />
     );
}

export default mail