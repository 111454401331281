import './App.css';
import Signup from './page/mail'
// import { TwitterShareButton } from "react-twitter-embed";

function App() {
  return (
    <>
      <div className='flex-container'>
        <div className='flex-item-left'>
        <img className='image-1' src={process.env.PUBLIC_URL + 'crypto-league-full.png'} alt=' '/>

         <div className='card-container'>    
          <div className='card'>
            <div className='card-picture-1'></div>
            <h1>M.S Dhoni</h1>
            <p><b>XP</b> - 51</p>
          </div>

          <div className='card'>
            <div className='card-picture-2'></div>
            <h1>Virat Kohli</h1>
            <p><b>XP</b> - 35</p>
          </div>

          <div className='card'>
            <div className='card-picture-3'></div>
            <h1>Rohit Sharma</h1>
            <p><b>XP</b> - 29</p>
          </div>
        </div> 

        </div>
        <div className='flex-item-right'>
          <div className='container'>
          <div className='flex-item-right-top'>
          {/* <div className="share-us">
            <TwitterShareButton
              url={`https://beta-crypto-league-register.netlify.app/`}
              options={{
                text: `World's first digital card game bringing back the fun of 90s card deck. Buy, sell, and collect authentic NFTs. Request early access to collect exclusive digital items.`,
                via: "CryptoLeagueHQ", 
                size: "large",
              }}
            />
          </div> */}

        <div className='title'>
        <h1>Play and win in <span>Crypto</span></h1>
        </div>
        <div className='main-content'>
          <h2>World's first digital card game bringing back the fun of 90s</h2>
          <h2>Buy, sell and collect authentic NFTs</h2>
        </div>
        
        <p>
          Request early access to collect exclusive digital items.
        </p>
          </div>
          <div className='flex-item-right-bottom'><Signup /></div>
          </div>
          
        </div>
      </div>
      <div className='flex-item-footer'>
          <a class="fab fa-discord fa-10x" href="https://discord.gg/Dzb2zktsmY"> </a> 
          <a class="fab fa-twitter fa-10x" href="https://twitter.com/CryptoLeagueHQ"> </a>
      </div>
    </>
  );
}

export default App;
